import { CheckBoxIcon } from 'src/shared/assets/icons';
import './styles.scss';

type BaseCheckboxAttributes = React.ComponentProps<"input">;

interface CheckboxProps extends BaseCheckboxAttributes {
	label: string;
}

export const Checkbox = (props: CheckboxProps) => {
	const {label, ...rest} = props;

	return (
		<label className="checkbox-wrapper relative">
			<input type="checkbox" className='checkbox' name={label} {...rest}/>
			<CheckBoxIcon className='check-icon'/>
			<span>{label}</span>
		</label>
	)
}

