import React, { useCallback, useEffect, useState } from 'react';
import Map, { MapProvider, Layer, MapRef, Source } from 'react-map-gl/maplibre';
import { Navbar } from 'src/widgets/Navbar';
import 'maplibre-gl/dist/maplibre-gl.css';
import './MainPage.scss';
import { APIs, ControlPanel } from 'src/widgets/ControlPanel';
import DrawControl from 'src/features/map/draw-polygon/draw-control';
import { useUnit } from 'effector-react';
import { $drawMode } from 'src/features/map/draw-polygon/store';
import maplibregl from 'maplibre-gl';
import { bbox } from '@turf/turf';
import { setPolygonCreated } from 'src/features/map/draw-polygon/store';

const MainPage: React.FC = (options: any) => {
    const mapRef = React.useRef<MapRef | null>(null);
    const drawControlRef = React.useRef<any>(null);
    const [features, setFeatures] = useState<any>({});
    const updatePolygonCreated = useUnit(setPolygonCreated);
    const popupId = 'popupId';

    const drawMode = useUnit($drawMode);

    useEffect(() => {
        APIs.getCategories();
        APIs.getFormats();
    }, []);

    const onUpdate = useCallback((e: any) => {
        setFeatures((currFeatures: any) => {
            const newFeatures = { ...currFeatures };
            for (const f of e.features) {
                newFeatures[f.id] = f;
            }
            return newFeatures;
        });

    }, []);

    const onDelete = useCallback((e: any) => {
        setFeatures((currFeatures: any) => {
            const newFeatures = { ...currFeatures };
            for (const f of e.features) {
                if (f.id) {
                    updatePolygonCreated(false);
                    drawControlRef.current?.delete(f.id);
                } else {
                    console.log('Feature ID is undefined:', f);
                }
            }
            return newFeatures;
        });
    }, [updatePolygonCreated]);

    const onSelect = useCallback((e: any) => {
        const feature = e.features[0];

        if (feature) {
          const bboxPolygon = bbox(feature);
          
          // The upper-right corner of the square is (maxLng, maxLat)
          const upperRightCorner = [bboxPolygon[2], bboxPolygon[3]];

          // Create the popup
          const popup = new maplibregl.Popup({
              closeButton: false,
              closeOnClick: true,
              closeOnMove: true,
              offset: [0, -5],
          })
              .setLngLat([upperRightCorner[0], upperRightCorner[1]])
              .setHTML(`<div id='${popupId}'><button class="trash-button"></button></div>`)
              .addTo(mapRef.current?.getMap() as maplibregl.Map);

          // Add event listener to the button
          popup.getElement().querySelector('.trash-button')?.addEventListener('click', () => {
          const popupElement = document.getElementById(popupId);
                if (popupElement) {
                    popupElement.remove();
                }
                onDelete({ features: [feature] });

                // Remove the panel with the area
                const panel = document.getElementById('draw-square-panel');
                if (panel) {
                    panel.remove();
                }

           

          });
        }
    }, [onDelete]);

    return (
        <>
        <Navbar/>
        <MapProvider>
            <Map
                ref={mapRef}
                initialViewState={{
                    longitude: 4.3,
                    latitude: 47.2,
                    zoom: 4,
                }}
                id={"mainMap"}
                style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 0 }}
                dragRotate={false}
                bearingSnap={0}
                maxPitch={0}
                minPitch={0}
            >
                <Source type='raster'
                    tileSize={256}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    tiles={['https://tile.openstreetmap.org/{z}/{x}/{y}.png']}
                >
                    <Layer type='raster' source='raster' />
                </Source>
                <ControlPanel plgnGeom={features} />
                {
                    drawMode ? (
                        <DrawControl
                            ref={drawControlRef}
                            position="bottom-right"
                            displayControlsDefault={true}
                            controls={{
                                polygon: true,
                                trash: true,
                            }}
                            defaultMode={'draw_square'}
                            onCreate={onUpdate}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                            onSelect={onSelect}
                        />
                    ) : null
                }
            </Map>
        </MapProvider>
        </>
    );
};

export default MainPage;
