import { forwardRef, useImperativeHandle } from 'react';
import MapboxDraw, { DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl/maplibre';
import DrawSquare from './drawSquareMode';
import { ControlPosition } from 'react-map-gl/maplibre';
import { customDrawStyles } from './customStyles';

interface CustomModes extends MapboxDraw.Modes {
  [modeKey: string]: DrawCustomMode<any, any>;
  draw_square: any;
}

const modes = MapboxDraw.modes as CustomModes;
modes.draw_square = DrawSquare;

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
  onSelect?: (evt: { features: object[]; action: string }) => void;
};

const DrawControl = forwardRef<MapboxDraw, DrawControlProps>((props, ref) => {
  const draw = useControl<MapboxDraw>(
    () => new MapboxDraw({
      ...props,
      styles: customDrawStyles,
      modes: modes,
      userProperties: true,
    }),
    ({ map }: any) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate);
      map.on('draw.delete', props.onDelete);
      map.on('draw.selectionchange', props.onSelect);
    },
    ({ map }: any) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
      map.off('draw.delete', props.onDelete);
      map.off('draw.selectionchange', props.onSelect);
    },
    {
      position: props.position,
    }
  );

  useImperativeHandle(ref, () => draw);
  return null;
});

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
  onSelect: () => {},
};

export default DrawControl;
