import { NavLink } from "react-router-dom";
import { Button } from "src/shared/ui";
import './FeedbackCard.scss';

const CARD_TITLE = "Feedback";
const CARD_TEXT = "We care about your feedback.\nProvide yours to make our service even better.";
const BTN_TEXT = "Give feedback";

export const FeedbackCard = () => {
	return (
		<div className={`popup-tail-content`} 
			 onClick={e => e.stopPropagation()}>
			<p className="popup-tail-content-text">
				{CARD_TITLE}
			</p>
			<div className='feedback-text'>
				<p className="whitespace-pre-wrap">
					{CARD_TEXT}
				</p>
			</div>
			<div className="feedback-button-wrapper"> 
					<NavLink to={'/feedback'}>
						<Button className='btn-giveFeedback'>{BTN_TEXT}</Button>
					</NavLink>
			</div>
		</div>
	)
}