import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ExpiredDataPage.scss';
import { ReactComponent as ExpiredDataLogo } from '../../../shared/assets/icons/svg/expired-data.svg';

const ExpiredDataPage: React.FC = () => {
    const [isFontLoaded, setIsFontLoaded] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkFont = () => {
            if (document.fonts) {
                document.fonts.ready.then(() => {
                    setIsFontLoaded(true);
                });
            } else {
                setIsFontLoaded(true);
            }
        };

        checkFont();
    }, []);

    const handleRedirect = () => {
        navigate('/');
    };

    if (!isFontLoaded) {
        return <div className="animated-spinner"></div>;
    }

    return (
        <div className="expired-data-page">
            <div className="content-container">
                <ExpiredDataLogo className="expiredDataLogo" />
                <p>We stored your data for 2 days, but it is no longer available. Feel free to explore the world further!</p>
                <button
                    onClick={handleRedirect}
                    className="btn-redirectToMainPage">
                    Go to SpatialHub
                </button>
            </div>
        </div>
    );
};

export default ExpiredDataPage;
