import React, { useState, useEffect } from 'react';
import './FeedbackPage.scss';
import { useNavigate } from 'react-router-dom';
import { SpatialApi } from 'src/shared/api/';
import { FeedbackForm } from 'src/shared/api/spatialhub-api';
import { Navbar } from "src/widgets/Navbar";

const FeedbackPage: React.FC = () => {
    const [feedbackText, setFeedbackText] = useState<string>('');
    const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
    const [isSubmitButtonActive, setSubmitButtonActive] = useState<boolean>(false);
    const [popupMessage, setPopupMessage] = useState<string>('');
    const [popupClass, setPopupClass] = useState<string>('');
    const [isTooManySymbolsVisible, setTooManySymbolsVisible] = useState<boolean>(false);
    const [isFeedbackEmptyVisible, setFeedbackEmptyVisible] = useState<boolean>(false);
    const [isFontLoaded, setIsFontLoaded] = useState<boolean>(false);

    const maxLength = 2000;
    const navigate = useNavigate();

    useEffect(() => {
        const checkFont = () => {
            if (document.fonts) {
                document.fonts.ready.then(() => {
                    setIsFontLoaded(true);
                });
            } else {
                // Fallback if Font Loading API is not supported
                setIsFontLoaded(true);
            }
        };

        checkFont();
    }, []);

    const updateCounter = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        setFeedbackText(text);

        if (text.length === 0) {
            setSubmitButtonActive(false);
        } else if (text.length > maxLength) {
            setTooManySymbolsVisible(true);
            setSubmitButtonActive(false);
        } else {
            setTooManySymbolsVisible(false);
            setSubmitButtonActive(true);
        }
    };

    const handleSubmit = async () => {
        if (feedbackText.trim() === '') {
            setFeedbackEmptyVisible(true);
            setSubmitButtonActive(false);
            return;
        } else {
            setFeedbackEmptyVisible(false);
            setSubmitButtonActive(true);
        }
    
        if (feedbackText.length <= maxLength) {
            const feedbackData: FeedbackForm = {
                feedback: feedbackText
            };
    
            try {
                const response = await SpatialApi.backend.backendApiFeedbackCreate(feedbackData)
                
                if (response.status === 200) {
                    const message = response.data.detail
                    setPopupMessage(`${message}\nIn 3 seconds you will be redirected to SpatialHub.`);
                    setSubmitButtonActive(false);
                    setPopupClass('success');
                    setPopupVisible(true);
                    setTimeout(() => {
                        setFeedbackText('');
                        setPopupVisible(false);
                        navigate('/');
                    }, 3000);
                }
    
            } catch (error: any) {
                setSubmitButtonActive(false);
                const message = error.error.feedback[0]
                setPopupMessage(`Oops...\n${message}`);
                setPopupClass('error');
                setPopupVisible(true);
                setTimeout(() => {
                    setPopupVisible(false);
                    setSubmitButtonActive(true);
                }, 4000);
                
            }
        } else {
            setTooManySymbolsVisible(true);
        }
    };
    
    if (!isFontLoaded) {
        return (
            <div className="animated-spinner"></div>
        );
    }

    return (
        <>
        <Navbar/>
        <div className="feedback-page">
            <div className="feedback-modal">
                <h2>Send feedback</h2>
                {isTooManySymbolsVisible && (
                    <div className="too-many-symbols">Too many symbols...</div>
                )}
                {isFeedbackEmptyVisible && (
                    <div className="feedback-empty">Type anything. The feedback could not be empty!</div>
                )}
                <p>We read every piece of feedback sent through this form. Share your experience with us. What’s working well? What could’ve gone better?</p>
                <textarea
                    id="feedbackText"
                    placeholder="Please, tell us a bit more"
                    maxLength={maxLength}
                    value={feedbackText}
                    onChange={updateCounter}
                    className={isTooManySymbolsVisible ? 'error' : ''}
                />
                <div className="footer">
                    <div className={`counter ${isTooManySymbolsVisible ? 'error' : ''}`}>
                        {feedbackText.length}/{maxLength} {isTooManySymbolsVisible && <span className="error-text">Too many symbols...</span>}
                    </div>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={isSubmitButtonActive === false}
                    >
                        Submit
                    </button>
                </div>
                {isPopupVisible && (
                    <div className={`popup ${popupClass}`}>{popupMessage}</div>
                )}
            </div>
        </div>
        </>
    );
};

export default FeedbackPage;
