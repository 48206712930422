import React from "react";
import './App.css'
import { AppRouter } from "./providers/router";

export const App: React.FC = () => {
    return (
        <>
            <AppRouter />
        </>
    );
};