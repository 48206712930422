import { RouteProps } from "react-router-dom"
import ContactPage from "src/pages/ContactPage/ui/ContactPage";
import MainPage from "src/pages/MainPage/ui/MainPage";
import FeedbackPage from "src/pages/FeedbackPage/ui/FeedbackPage";
import ExpiredDataPage from "src/pages/ExpiredDataPage/ui/ExpiredDataPage";
import NotFoundPage from "src/pages/NotFoundPage/ui/NotFoundPage";

export enum AppRoutes {
    MAIN = 'main',
    CONTACT = 'contact',
    FEEDBACK = 'feedback',
    EXPIRED_DATA = 'expired',
    NOT_FOUND = 'notFound',
}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.CONTACT]: '/contact',
    [AppRoutes.FEEDBACK]: '/feedback',
    [AppRoutes.EXPIRED_DATA]: '/expired',
    [AppRoutes.NOT_FOUND]: ""
}

export const routeConfig: Record<AppRoutes, RouteProps> = {
    [AppRoutes.MAIN]: {
        path: RoutePath.main,
        element: <MainPage />
    },
    [AppRoutes.CONTACT]: {
        path: RoutePath.contact,
        element: <ContactPage />
    },
    [AppRoutes.FEEDBACK]: {
        path: RoutePath.feedback,
        element: <FeedbackPage />
    },
    [AppRoutes.EXPIRED_DATA]: {
        path: RoutePath.expired,
        element: <ExpiredDataPage />
    },
    // Add a catch-all route for unmatched paths
    [AppRoutes.NOT_FOUND]: {
        path: '*', 
        element: <NotFoundPage /> 
    }
}