import { PropsWithChildren, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { CloseIcon } from 'src/shared/assets/icons';
import { Button } from "src/shared/ui";

import './styles.scss';

type DialogProps = {
	opened?: boolean;
	title?: string;
	className?: string;
	tailContent?: ReactNode;
	onClose?: () => void;
}

export const Dialog = ({opened, title, className, tailContent, children, onClose}:PropsWithChildren<DialogProps>) => {
	const [openDialog, setOpenDialog] = useState(true);

	const toggleDialogDefault = () => {
		setOpenDialog(!openDialog);
	};
	
	return (opened
			? createPortal(
					<div className="bg" onClick={onClose? onClose: toggleDialogDefault}>
						<div className='popup-wrapper'>
							<div className='flex flex-col h-[500px]'>
								<div className={`${className}`} onClick={e => e.stopPropagation()}>
									<div className='flex p-0 pb-4 m-0 h-fit w-full justify-end'>
										<Button className='p-0 w-[20px]'
												leftIcon={<CloseIcon/>} 
												onClick={onClose? onClose: toggleDialogDefault}/>
									</div>
									<div className={`popup-title ${title? '' : 'popup-title-empty'}`}>
										{title}
									</div>
									{children}
								</div>
								{
									tailContent ? (
										tailContent
									) : null
								}
							</div>
						</div>
					</div>,
				document.body
			  )
			: null);
}
