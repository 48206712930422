import clsx from 'clsx';
import './divider.scss';

type BaseDivAttributes = React.ComponentPropsWithoutRef<"div">;

interface DivProps extends BaseDivAttributes {
	className?: string,
	type?:  "horizontal" | "vertical";
}

export const Divider = (props: DivProps) => {
	const {className, type, ...rest} = props;

	const merged = clsx("divider", className);

	return (
		<div className={merged} {...rest}>
		</div>
	)
}