import { LoaderIcon } from 'src/shared/assets/icons';
import './styles.scss';
import { Button, Checkbox } from 'src/shared/ui';
import { useEffect, useMemo, useState } from 'react';
import { useUnit } from 'effector-react';
import {
  $categoriesStore,
  $dataFormat,
  $emailStore,
  $loadingCategories,
  sendData,
  setDataFormatEvent,
  setEmailEvent, 
  $formatsStore,
  $loadingFormats,
  resetAllFields
} from './store';
import { $isPolygonCreated, setDrawMode, setPolygonCreated } from 'src/features/map/draw-polygon/store';
import { GisFeatureEnum } from 'src/shared/api/spatialhub-api';
import React from 'react';
import { useStatusCard } from 'src/widgets/StatusCard';
import { DonationCard } from '../DonationCard';

type ControlPanelProps = {
  plgnGeom: any;
}

type LayerControl = {
  name: string;
  checked: boolean;
}

export const ControlPanel = ({ plgnGeom }: ControlPanelProps) => {
  const [layers, setLayers] = useState<LayerControl[] | []>([]);
  const [allLayersSelected, setAllLayersSelected] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null); // State for email error
  const isPolygonCreated = useUnit($isPolygonCreated);

  const setSelectedDataFormat = useUnit(setDataFormatEvent);
  const selectedDataFormat = useUnit($dataFormat);
  const email = useUnit($emailStore);
  const categories = useUnit($categoriesStore);
  const formats = useUnit($formatsStore);
  const loadingCategories = useUnit($loadingCategories);
  const loadingFormats = useUnit($loadingFormats);
  const resetFields = useUnit(resetAllFields);
  const { showStatus, ModalComponent } = useStatusCard();

  useEffect(() => {
    resetFields(); 
    setDataFormatEvent('');
    setEmailEvent('');
  
    if (categories) {
      setLayers(categories?.map(({ name }) => ({ name, checked: false })) ?? []);
    }
  }, [categories, resetFields]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@,]+@[^\s@,]+\.[^\s@,]+$/;
    return emailRegex.test(email);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmailEvent(value);

    if (value === '') {
        setEmailError(null); // Clear the error message if input is empty
    } else if (!validateEmail(value)) {
        setEmailError('Invalid e-mail format');
    } else {
        setEmailError(null);
    }
};

  const onSelectLayer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setLayers((prev) => {
      const idx = prev.findIndex(({ name }) => name === event.target.name);
      const newList = [...prev];
      newList[idx] = { name: name, checked: checked };
      return newList;
    });
  }

  const onSelectFormat = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedDataFormat((event.target as HTMLInputElement).value);
  }

  const onSelectAllLayers = () => {
    setAllLayersSelected(prevState => !prevState); // Toggle the state
    setLayers(prev => prev.map(item => ({ ...item, checked: !allLayersSelected })));
  }

  const isDisabledBtn = useMemo(() =>
    !(layers.filter(item => item.checked === true).length > 0
      && (email ?? '').toString().length > 0
      && (selectedDataFormat ?? '').length > 0
      && isPolygonCreated)
    , [layers, email, selectedDataFormat, isPolygonCreated]);

  const onSubmit = async () => {
    if (emailError) return; // Prevent submission if email is invalid

    await sendData({
      type: GisFeatureEnum.Feature,
      geometry: plgnGeom[Object.keys(plgnGeom)[0]].geometry,
      properties: {
        email: email ?? '',
        selectedCategories: layers.filter(({ checked }) => checked === true).map(({ name }) => name).join(", "),
        fileFormat: selectedDataFormat ?? '',
      }
    }).then(res => {
      resetFields();
      setLayers(prev => prev.map(item => ({ ...item, checked: false })))
      showStatus("ok");
      setDrawMode(null);

        const popupElement = document.getElementById('popupId');
        if (popupElement) {
            popupElement.remove();
        }

      setPolygonCreated(false)
      const panel = document.getElementById('draw-square-panel');
      if (panel) {
          panel.remove();
      }

      // Deselect all layers
      setAllLayersSelected(false);
    
    }).catch((err) => {
      showStatus("error")
    });
  }

  return (
    <div className="control-panel">
      <div className="layers-panel" key='layers'>
        <div className='layers-header'>
          <div className='layers-title'>
            {'Layers'}
            {/* <InfoIcon /> */}
          </div>
          <Button onClick={onSelectAllLayers}>
            {allLayersSelected ? 'Deselect all' : 'Select all'}
          </Button>
        </div>
        {
          loadingCategories
            ? <LoaderIcon style={{
                display: 'flex',
                margin: 'auto',
                height: '100%'
              }} />
            : null
        }

        {
          layers.map(({ name, checked }, idx) => (
            <div className='layers-item'
              key={`layer-${idx}`}>
              <Checkbox label={name}
                checked={checked}
                onChange={onSelectLayer} />
            </div>
          ))
        }
      </div>

      <div className="format-panel" key='format-list'>
        <div className='layers-title' key={'format-title'}>
          {'Format'}
        </div>
        <div className='format-wrapper'>
          {
            loadingFormats
              ? <LoaderIcon style={{
                display: 'flex',
                margin: '-5px auto 5px',
                alignSelf: 'center',
                height: '100%'
              }} />
              : formats?.map(({ name }, idx) => (
                <Button className={`format-item ${selectedDataFormat === name ? 'format-item-selected' : ''}`}
                  onClick={onSelectFormat}
                  value={name}
                  key={`format-${idx}`}>{name}</Button>
              ))
          }
        </div>
      </div>

      <div className="email-panel" key='email-area'>
            <div className='layers-title' key={'email-title'}>
                {'E-mail'}
            </div>
            <div className='email-wrapper'>
                <input
                    type="text"
                    placeholder='E-mail@example.com'
                    className={`email-content ${emailError ? 'email-error' : ''}`}
                    onChange={onEmailChange}
                    value={email ?? ''}
                />
                {emailError && (
                <div className="email-error-message">
                    {/* <InfoIcon className="info-icon"/> */}
                    {emailError}
                </div>
              )}

            </div>
        </div>

      <Button className={'btn-submit'} disabled={isDisabledBtn || !!emailError}
              type='submit'
              onClick={onSubmit}>
          {'Send data'}
      </Button>
      <ModalComponent>
        <DonationCard title={'Support us with donations'}/>
      </ModalComponent>
    </div>
  );
}
