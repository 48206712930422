import { forwardRef, useMemo } from "react";
import clsx from 'clsx';
import './button.scss';
import { LoaderIcon } from "src/shared/assets/icons";

type BaseButtonAttributes = React.ComponentPropsWithoutRef<"button">;
type Ref = HTMLButtonElement;

interface ButtonProps extends BaseButtonAttributes {
  isLoading?: boolean;
  disabled?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  className?: string,
  variant?:  "solid" | "outline" | "blue";
}

const getVariant = (variant: "solid" | "outline" | "blue") => {
  switch (variant) {
    case "outline":
      return "btn-outline";
    case "blue":
      return "btn-blue";
    default:
      return undefined;
  }
};


export const Button = forwardRef<Ref, ButtonProps>((props, ref) => {
  const { type, children,  variant, disabled, isLoading, leftIcon, rightIcon,className, ...rest } = props;

  const merged = clsx("btn", getVariant(variant!!),className);

  const { newIcon: icon, iconPlacement } = useMemo(() => {
    let newIcon = rightIcon || leftIcon;

    if (isLoading) {
      newIcon = <LoaderIcon />;
    }

    return {
      newIcon,
      iconPlacement: rightIcon ? ("right" as const) : ("left" as const),
    };
  }, [isLoading, leftIcon, rightIcon]);


  return (
    <button
      {...rest}
      type={type ? "submit" : "button"}
      ref={ref}
      disabled={disabled || isLoading}
	    className={merged}
    >
      {/** render icon before */}
      {icon && iconPlacement === "left" ? (
        <span className={`inline-flex shrink-0 self-center ${children && !isLoading && "mr-[2px]"}`}>{icon}</span>
      ) : null}

      {/** hide button text during loading state */}
      {!isLoading && children}

      {/** render icon after */}
      {icon && iconPlacement === "right" ? (
        <span className={`inline-flex shrink-0 self-center  ${children && !isLoading && "ml-[2px]"}`}>{icon}</span>
      ) : null}
    </button>
  );
});

// set default props
Button.defaultProps = {
  isLoading: false,
  disabled: false,
  leftIcon: undefined,
  rightIcon: undefined,
};
