import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFoundPage.scss';

const NotFoundPage: React.FC = () => {
    const [isFontLoaded, setIsFontLoaded] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkFont = () => {
            if (document.fonts) {
                document.fonts.ready.then(() => {
                    setIsFontLoaded(true);
                });
            } else {
                setIsFontLoaded(true);
            }
        };

        checkFont();
    }, []);

    const handleRedirect = () => {
        navigate('/');
    };

    if (!isFontLoaded) {
        return <div className="animated-spinner"></div>;
    }

    // Make sure to include the return statement for the main content
    return (
        <div className="not-found-page">
            <div className="content-container">
                <p>The page you are looking for does not exist.</p>
                <button
                    onClick={handleRedirect}
                    className="btn-redirectToMainPage">
                    Go to SpatialHub
                </button>
            </div>
        </div>
    );
};

export default NotFoundPage;
