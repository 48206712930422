import { useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useUnit } from "effector-react";
import { AddIcon, SpHubLogoIcon } from "src/shared/assets/icons";
import { AppLink, Button, Dialog, Divider } from "src/shared/ui";
// import { BaseMapSelector } from "src/features/map/change-style";

import { DrawStore } from 'src/features/map/draw-polygon';
import { DonationCard } from "src/widgets/DonationCard";
import './Navbar.scss';
import FeedbackCard from 'src/widgets/FeedbackCard';

interface NavbarProps {
    className?: string;
}

const DONATE_TITLE = "Support us with donations";
const DONATE_TEXT = "Your financial support helps us continue in our mission, implement new features and achieve our goals of easy and quality geospatial date collection";

export const Navbar = ({ className }: NavbarProps) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openAboutDialog, setOpenAboutDialog] = useState<boolean>(false);
    const setMode = useUnit(DrawStore.setDrawMode);
    const location = useLocation();
    const EmptyNavbarPaths = [ '/feedback', '/feedback/', '/expired', '/expired/' ]; // List of pages to exclude from the navbar

    // Reset drawing state when navigating to any page
    useEffect(() => {
        // Reset the mode every time the location changes
        setMode(null);

        // Clean up any leftover DOM elements if necessary
        const popupElement = document.getElementById('popupId');
        if (popupElement) popupElement.remove();

        const panel = document.getElementById('draw-square-panel');
        if (panel) panel.remove();

        // Reset polygon drawing state
        DrawStore.setPolygonCreated(false);
    }, [location.pathname, setMode]);


    // Toggles
    const toggleDonationDialog = () => {
        setOpenDialog(!openDialog);
    }

    const toggleAboutDialog = () => {
        setOpenAboutDialog(!openAboutDialog);
    };

    const onClick = () => {
        // Сначала сбрасываем режим
        setMode(null);

        const popupElement = document.getElementById('popupId');
        if (popupElement) {
            popupElement.remove();
        }

        // Сбрасываем состояние отрисовки полигона
        DrawStore.setPolygonCreated(false);

        // Потом удаляем панель с текущей площадью
        const panel = document.getElementById('draw-square-panel');
        if (panel) {
            panel.remove();
        }

        // Затем устанавливаем его снова через небольшой таймаут, чтобы сброс применился
        setTimeout(() => {
            setMode('draw_square');
        }, 0);
    }

     // Conditionally render based on the route
     if (EmptyNavbarPaths.includes(location.pathname)) {
        return ( <div className="Navbar">
                    <div className="Navbar_main__wrapper">
                        <AppLink className='Navbar_main Navbar_link' to={'/'} > SpatialHub</AppLink>
                    </div>
                    <div className="mr-2">
                        <Button
                            variant={'blue'}
                            onClick={toggleDonationDialog}>
                            {'Donate'}
                        </Button>
                    </div>
                    <Dialog opened={openDialog} 
                            title={DONATE_TITLE}
                            onClose={toggleDonationDialog}
                            className="popup-donation-btn">
                        <div className='popup-text mb-4'>
                            {DONATE_TEXT}
                        </div>
                        <DonationCard/>
                    </Dialog>
                </div> );
    } else {
    return (
        <div className="Navbar">
            <div className="Navbar_main__wrapper">
                <AppLink className='Navbar_main Navbar_link' to={'/'}>SpatialHub</AppLink>
            </div>
            <div className="Navbar_other">
                <Button
                    className='select-area-btn'
                    leftIcon={<AddIcon style={{ marginRight: '4px' }}/>}
                    onClick={onClick}>
                    {'Select Area'}
                </Button>
                <Divider/>
                <Button
                    className='about-us-btn'
                    onClick={toggleAboutDialog}>About Us
                </Button>
            </div>

            <div className="mr-2">
                <Button
                    variant={'blue'}
                    onClick={toggleDonationDialog}>
                    {'Donate'}
                </Button>
            </div>

            <Dialog opened={openDialog} 
                    title={DONATE_TITLE}
                    onClose={toggleDonationDialog}
                    className="popup-donation-btn">
				<div className='popup-text mb-4'>
					{DONATE_TEXT}
				</div>
                <DonationCard/>
            </Dialog>
            {/* About Us Dialog */}
            <Dialog 
                opened={openAboutDialog}
                onClose={toggleAboutDialog}
                tailContent={<FeedbackCard/>}
                className="about-dialog">
                <div className="popup-logo-wrapper">
                    <SpHubLogoIcon className="SpatialHubLogo" />
                </div>
                {/* Title below the logo */}
                <div className="popup-title">
                    {"About SpatialHub"}
                </div>
                <div className='popup-text-1'>
                    <p>{"We are a group of passionate enthusiasts working to prepare and provide spatial data for everyone."}</p>
                    <p><strong>{"Our goal is to create a free service that will be useful for all"}</strong>{" — from beginners to professionals — offering access to spatial data that can help improve our world."}</p>
                    <p>{"Our mission is to make spatial data accessible and free, so enthusiasts from around the globe can work on their projects and make a difference!"}</p>
                </div>
            </Dialog>

        </div>
    );
    }
}