import { createEffect, createEvent, createStore } from "effector";
import { SpatialApi } from "src/shared/api";
import { Categories, SaveSendData, Formats } from "src/shared/api/spatialhub-api";

export const resetAllFields = createEvent();
export const setDataFormatEvent = createEvent<string>();
export const $dataFormat = createStore<string|null>(null).on(setDataFormatEvent, (_, current) => current).reset(resetAllFields);

export const setEmailEvent = createEvent<string>();
export const $emailStore = createStore<string|null>(null).on(setEmailEvent, (_, current) => current).reset(resetAllFields);

// Get categories
export const getCategories = createEffect(() => 
	SpatialApi.backend.backendApiCategoriesList({}).then(({data}) => data as Categories[])
);
export const $categoriesStore = createStore<Categories[]|null>(null).on(getCategories.doneData, (_, payload) => payload);
export const $loadingCategories = getCategories.pending;

// Get formats
export const getFormats = createEffect(() => 
	SpatialApi.backend.backendApiFormatsList({}).then(({data}) => data as Formats[])
);
export const $formatsStore = createStore<Formats[]|null>(null).on(getFormats.doneData, (_, payload) => payload);
export const $loadingFormats = getFormats.pending;

// Send Data
export const sendData = createEffect((data: SaveSendData) => 
	SpatialApi.backend.backendApiSendEmailCreate(data)
);
