import { DrawCustomMode, DrawCustomModeThis } from '@mapbox/mapbox-gl-draw';
import { GeoJSON } from 'geojson';
import proj4 from 'proj4';
import * as jsts from 'jsts';
import { setPolygonCreated } from 'src/features/map/draw-polygon/store';

const polygonId = 'polygonId';

const DrawSquare: DrawCustomMode = {
  onSetup(opts) {
    const square = this.newFeature({
      id: polygonId,
      type: 'Feature',
      properties: {
        areaLimit: 4000,
        area_exceed: false,
        init_state: true,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]],
      },
    });

    this.addFeature(square);
    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false,
    });

    // Cursor to crosshair
    this.map.getCanvas().style.cursor = 'crosshair';

    // Create a panel to show the area
    const panel = document.createElement('div');
    panel.id = 'draw-square-panel';
    panel.className = 'draw-square-panel';
    panel.innerHTML = `Area: 0.0 / ${square.properties?.areaLimit} km²`;
    document.body.appendChild(panel);

    return { square,
             drawing: false,
             clickCount: 0,
             onMouseMoveBound: undefined,
             startCoordinates: [],
             panel,
            };
  },

  onClick(state, e) {
    const { square, clickCount } = state;

    if (!state.startCoordinates) {
        console.error('State startCoordinates is not defined');
        return;
    }

    if (clickCount === 0) {
        const start = [e.lngLat.lng, e.lngLat.lat];
        state.startCoordinates = start;

        state.drawing = true;
        state.clickCount = 1;

        // Show the panel
        const panel = document.getElementById('draw-square-panel');
        if (panel) {
            panel.style.display = 'block';
        }
    } else if (clickCount === 1) {
        // Get the coordinates from the square in EPSG:4326
        const coordinates4326 = square.getCoordinates()[0];

        // Transform the coordinates from EPSG:4326 to EPSG:3857
        const coordinates3857 = coordinates4326.map((coord: number[]) => proj4('EPSG:4326', 'EPSG:3857', coord));

        // Create a JSTS polygon using the transformed coordinates
        const geometryFactory = new jsts.geom.GeometryFactory();
        const shell = coordinates3857.map((coord: number[]) => new jsts.geom.Coordinate(coord[0], coord[1]));
        const linearRing = geometryFactory.createLinearRing(shell);
        const polygon = geometryFactory.createPolygon(linearRing);

        // Calculate the area in square meters (EPSG:3857 is in meters)
        const areaSquareMeters = polygon.getArea();
        const areaSquareKilometers = areaSquareMeters / 1e6; // Convert to square kilometers

        // Check the area limit
        if (areaSquareKilometers > square.properties.areaLimit) {
            console.warn('Area exceeds the limit. Continue drawing.');
            return;
        }

        // Stop drawing if the area is within the limit
        if (state.onMouseMoveBound) {
            this.map.off('mousemove', state.onMouseMoveBound);
        }

        state.drawing = false;
        state.clickCount = 0;
        square.properties.init_state = false;

        // Update features in Square
        this.map.fire('draw.update', {
            features: [square.toGeoJSON()],
        });

        // Change mode
        this.changeMode('simple_select');
    }
},

    onMouseMove(state, e) {
        if (state.clickCount === 1) {
        const { square, drawing } = state;
        const start = state.startCoordinates;

        if (drawing && square) {
            const current = [e.lngLat.lng, e.lngLat.lat];

            // Transform start and current coordinates from EPSG:4326 to EPSG:3857
            const start3857 = proj4('EPSG:4326', 'EPSG:3857', start);
            const current3857 = proj4('EPSG:4326', 'EPSG:3857', current);

            // Calculate the width and height difference in EPSG:3857
            const dx = current3857[0] - start3857[0];
            const dy = current3857[1] - start3857[1];

            // Determine the length of the square's side based on the larger dimension
            const sideLength = Math.max(Math.abs(dx), Math.abs(dy));

            // Calculate each corner of the square
            const topLeft3857 = start3857;
            const topRight3857 = [start3857[0] + sideLength * Math.sign(dx), start3857[1]];
            const bottomRight3857 = [start3857[0] + sideLength * Math.sign(dx), start3857[1] + sideLength * Math.sign(dy)];
            const bottomLeft3857 = [start3857[0], start3857[1] + sideLength * Math.sign(dy)];

            // Generate the new square coordinates in EPSG:3857
            const tempCoordinates3857 = [
            topLeft3857,
            topRight3857,
            bottomRight3857,
            bottomLeft3857,
            topLeft3857
            ];
            
            // Convert the square coordinates back to EPSG:4326
            const tempCoordinates = tempCoordinates3857.map(coord =>
            proj4('EPSG:3857', 'EPSG:4326', coord)
            );

            // Create a polygon geometry in EPSG:3857 using JSTS
            const geometryFactory = new jsts.geom.GeometryFactory();
            const shell = tempCoordinates3857.map(coord => new jsts.geom.Coordinate(coord[0], coord[1]));
            const linearRing = geometryFactory.createLinearRing(shell);
            const polygon = geometryFactory.createPolygon(linearRing);

            // Calculate the area of the polygon in EPSG:3857 (square meters)
            const areaSquareMeters = polygon.getArea();
            const area = areaSquareMeters / 1e6;

            // Update the square feature with the new coordinates
            square.setCoordinates([tempCoordinates]);

            if (area > state.square.properties.areaLimit) {
                state.square.properties.area_exceed = true;
                state.panel.classList.add('panel-exceeds-limit');
                state.panel.classList.remove('panel-within-limit');
            } else {
                state.square.properties.area_exceed = false;
                state.panel.classList.add('panel-within-limit');
                state.panel.classList.remove('panel-exceeds-limit');
            }

            // Update the panel with the area information
            const panel = document.getElementById('draw-square-panel');
            if (panel) {
              panel.innerHTML = `Area: ${area.toFixed(1)} / ${state.square.properties.areaLimit} km²`;
            }

            this.map.fire('draw.update', {
            features: [square.toGeoJSON()],
            });
        }
        }
    },


  onStop(state) {
    const { square } = state;
    setPolygonCreated(true);
    this.map.getCanvas().style.cursor = 'pointer'; // move, grab, https://docs.mapbox.com/mapbox-gl-js/example/drag-a-point/ 

    this.map.fire('draw.update', {
      features: [square.toGeoJSON()],
    });    
  },

  toDisplayFeatures(
    this: DrawCustomModeThis & DrawCustomMode<any, any>,
    state: any,
    geojson: GeoJSON,
    display: (geojson: GeoJSON) => void): void {
    display(geojson);
  },
};

export default DrawSquare;
