import { Link } from "react-router-dom";
import { BoostyIcon, CloudTipIcon, PatreonIcon } from "src/shared/assets/icons";
import './DonationCard.scss';

const DONATE_LINKS = [
    {
        name: "Patreon",
        icon: <PatreonIcon/>,
        link: "https://patreon.com/SpatialHub"
    },
    {
        name: "Boosty",
        icon: <BoostyIcon/>,
        link: "https://boosty.to/spatialhub"
    },
    {
        name: "CloudTips",
        icon: <CloudTipIcon/>,
        link: "https://pay.cloudtips.ru/p/a52164e8"
    }
]

type DonationCardProps = {
    title?: string
}

export const DonationCard = ({title}: DonationCardProps) => {
	return (
        <div className={title? 'popup-tail-content popup-donation': ''} 
            onClick={e => e.stopPropagation()}>
            {
                title ? (
                    <p className='popup-tail-content-text'>
                        {title}
                    </p>
                ) 
                : null
            }
            <div className="p-0 m-0 mt-2 flex flex-row justify-around">
                {
                    DONATE_LINKS.map((item, key) => (
                        <div className="p-0 m-0 w-[69px]" key={`${key}-${item}`}>
                            <Link to={item.link} target="_blank">
                                {item.icon}
                            </Link>
                            <p className="text-center px-0 py-2 p-link">
                                {item.name}
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
	)
}