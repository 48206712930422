export const blue = '#17A1FA'
export const red = '#D2042D'

export const customDrawStyles = [
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": [
        "case",
        ["!", ["to-boolean", ["get", "area_exceed"]]],
        "#fbb03b",
        "#ff0000",
      ],
      "fill-opacity": 0.2,
    },
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["!", ["to-boolean", ["get", "area_exceed"]]],
        "#fbb03b",
        "#ff0000",
      ],
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: ['all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static']
    ],
    paint: {
     "fill-color": [
      "case",
        ["==", ["get", "user_area_exceed"], true],
        red, 
        blue,
      ],
      'fill-outline-color': 'blue',
      'fill-opacity': 0.1
    }
  },
{
  id: 'gl-draw-polygon-stroke-inactive-dashed',
  type: 'line',
  filter: ['all',
    ['==', 'active', 'false'],
    ['==', '$type', 'Polygon'],
    ['!=', 'mode', 'static'],
    ['==', 'user_init_state', true]
  ],
  layout: {
    'line-cap': 'round',
    'line-join': 'round'
  },
  paint: {
    "line-color": [
      "case",
      ["==", ["get", "user_area_exceed"], true],
      red, 
      blue,
    ],
    "line-dasharray": [0.2, 2],
    "line-width": 2,
  }
},
{
  id: 'gl-draw-polygon-stroke-inactive-solid',
  type: 'line',
  filter: ['all',
    ['==', 'active', 'false'],
    ['==', '$type', 'Polygon'],
    ['!=', 'mode', 'static'],
    ['==', 'user_init_state', false]
  ],
  layout: {
    'line-cap': 'round',
    'line-join': 'round'
  },
  paint: {
    "line-color": [
      "case",
      ["==", ["get", "user_area_exceed"], true],
      red, 
      blue, 
    ],
    "line-dasharray": [1, 0],
    "line-width": 2,
  }
}
  // {
  //   'id': 'gl-draw-vertex-outer',
  //   'type': 'circle',
  //   'filter': [
  //     'all',
  //     ['==', '$type', 'Point'],
  //     ['==', 'meta', 'vertex'],
  //     ['!=', 'mode', 'simple_select'],
  //   ],
  //   'paint': {
  //     'circle-radius': [
  //       'case',
  //       ['==', ['get', 'active'], 'true'], 7,
  //       5,
  //     ],
  //     'circle-color': 'blue',
  //   },
  // },
];