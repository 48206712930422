import { PropsWithChildren, useState } from "react";
import { Dialog } from "../../shared/ui/Dialog";
import { StatusError, StatusOk } from "src/shared/assets/icons";
import { Button } from "src/shared/ui";
import "./StatusCardStyles.scss";

const STATUS_OK_MSSG = "Request is proceeded";
const STATUS_OK_TEXT = "Expect an email from us. We will send all data about the area within several minutes";
const STATUS_ERR_MSSG = "Something went wrong. \n Please try again.";
const STATUS_ERR_TEXT = "An unexpected error occurred, sorry about that. We’ve been notified and started on a fix";

export const useStatusCard = () => {
	const [openDialog, setOpenDialog] = useState(false);
	const [status, setStatus] = useState<string>('');

	const onClose = () => setOpenDialog(false);

	const showStatus = (value: "ok" | "error") => {
		setOpenDialog(true);
		setStatus(value);
	}

	const ModalComponent: React.FC<PropsWithChildren> = ({children}) => (
			<Dialog 
					opened={openDialog}
					onClose={onClose}
					tailContent={children}
					className="popup-status-card">
				<div className="dialog-container">
					<div className="status-icon-container">
						{
							status === "ok" 
							? <StatusOk /> 
							: <StatusError />
						}
					</div>

					<p className="status-message">
						{
							status === "ok" 
							? STATUS_OK_MSSG
							: STATUS_ERR_MSSG
						}
					</p>

					<p className="status-details">
						{
							status === "ok" ? (
								STATUS_OK_TEXT
							) : (
								STATUS_ERR_TEXT
							)
						}
					</p>
					<Button
							variant="solid"
							className="btn-close"
							onClick={onClose}>
						{'Close'}
					</Button>
				</div>
			</Dialog>
	);

	return { showStatus, ModalComponent };
}
